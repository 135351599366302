import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'

var httpClient = new HttpClient(ApiGateway.Pas);

export default {
  getGeneralStatistics(clinicID = "") {
    var query = {
      clinicID,
    }
    return httpClient.get(`/Report/GeneralStatistics`, query)
  },
  getDailyRevenues(startDate, endDate) {
    var query = {
      startDate,
      endDate
    }
    return httpClient.get(`/Report/DailyRevenues`, query)
  },
  getDailyPatientCounts(startDate, endDate) {
    var query = {
      startDate,
      endDate
    }
    return httpClient.get(`/Report/DailyPatientCounts`, query)
  },
  getMonthlySaleIncreaseStatistics() {
    return httpClient.get(`/Report/MonthlySaleIncreaseStatistics`)
  },
  getMonthlyPatientIncreaseStatistics() {
    return httpClient.get(`/Report/MonthlyPatientIncreaseStatistics`)
  },
  getTopPayingPatients(count = 4) {
    var query = {
      count,
    }
    return httpClient.get(`/Report/TopPayingPatients`, query)
  },
}